import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Legal = ({ data, location }) => {
  //const siteTitle = data.site.siteMetadata.title

  return (
    <StaticQuery query={graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  ` 
   } 
    render = {data=>(
    <Layout location={location} title={data.site.siteMetadata.title} crumbLabel="Legal">
      <SEO title="Legal" />
      <h1>Data protection</h1>
      <h2>Last updated 30.01.2019</h2>
      <h2>This data protection policy is applicable for the app fingerino and Infinite Geometry 2 on the Apple App Store and the Google Play Store</h2>
      <p>We do not collect any personal data. However some data may be collected by third partys.</p>
      <h2>Third party services</h2>
      <p>We would like to serve you with great ads experiences on our apps. To achieve that, we cooperate with advertising partners to make the ads matched with you and your preferences. We may share your information with following providers.</p>
      <ul>
          <li>
              Unity: <a href="https://unity3d.com/legal/privacy-policy">Privacy Policy</a>
          </li>
      </ul>
    </Layout>
    )}/>
  )
}

export default Legal
